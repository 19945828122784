* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

.menu-icon {
  display: none;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  top:0;
  position: fixed;
  z-index: 1;
  transition-timing-function: ease-in;
  transition: all 0.8s;
  align-items: center;
  height: 70px;
  color: white;
}

.nav-links {
  display: flex;
  list-style: none;
  text-transform: uppercase;
}

.nav-item:hover {
  color: orange;
  cursor: pointer;
  background-color: black;
}

 h1 {
  color: #ffc342;
}

.title-span {
  color: black;
}

.nav-item {
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 2px;
}

.navbar-show-color {
  background: black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}



.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
}


.my-name {
  font-family: 'Raleway', sans-serif;
  font-size: 40px;


}

.surname {
  color: #ffc342;
}

.hero-content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color:white;
  padding: 50px;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.line-style {
  border: 1px solid #ffc342;
  width: 20%;
  margin-top: -30px;
}

.brand-logo {
  color:white;
  font-size: 25px;
  letter-spacing: 2px;
  padding: 10px;
  transition: all .25s;
  border-top: 2px solid orange;
  border-bottom: 2px solid orange;
  border-left: none;
  border-right: none;
  background-color: Transparent;
  cursor: pointer;
  font-weight: bold;
}

.brand-logo:hover {
  letter-spacing: 4px;
  margin-left: 15px;
}

//About me section styling
.about-us-container {
  max-width: 1035px;
  margin: 0 auto;
  display: flex;
  padding: 5px 50px 50px 50px;
}

.grid-row {
  flex-basis: 50%;
  margin-top: 5px;
}

.grid-row:nth-of-type(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.grid-row:nth-of-type(1) > img {
  object-fit: contain;
}

.grid-row:nth-of-type(2) {
  text-align: center;
  align-self: center;
}


//Skill section styling
.skill-section {
  background-color: black;
  height: 66vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.skills-grid {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills-grid-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colored {
  font-size: 90px;
  margin: 0 5px;

} 

//Portfolio section styling
.project-section {
  padding: 10 30px;
  background-color: black;
  color:white;
}





.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 70px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-right: 40px;
}

.blue-column {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-column {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}


//Contact me section
.contact-me {
  height: 60vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1000px;
  margin:0 auto;
}

.contact-me > div {
  border: 2px solid white;

}

.contact-me-details {
  display: flex;
  flex-direction: row;
}


.contact-me-details > div {
  flex: 1;
  height: 200px;
  border: 3px solid orange;
  margin: 0 30px;
  background-color: black;
}

.fa-icon {
  font-size: 40px;
  color: orange;
}

.github-title,.email-title,.linkedin-title {
  color: orange;
  font-weight:bold;
  letter-spacing: 1px;
}

.fa-icon-contact {
  font-size: 40px;
  color: orange;
}

@media (max-width: 800px) {
  .hero {
    height: 90vh;
  }

  .about-us-container {
    flex-direction: column-reverse;
    min-height: 0px;
  }

  .row {
    flex-direction: column;

  }

  .contact-me-details {
    flex-wrap: wrap;
    flex-direction: column;
    height: 500px;
    margin: 0 50px;
    padding: 15px;
  }


  

  .contact-me-details > div {
    margin-bottom: 10px;
  }
 
}



.default-color {
  color:white;
}


@media (max-width: 848px) {
  
  .row {
    padding: 10px;
    text-align: center;
  }

  .placeholder_img {
    width: 100% !important;
    height: 40vh;
  }

  nav {
    display: flex;
    flex-direction: column;
    background-color: black;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .nav-links {
    border-left: 2px solid orange;
    border-right: 2px solid orange;

    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: black;
    padding-top: 10px;
    margin-top: 57px;
    display: none;
  }

  

  .nav-links.active {
    display: block;
  }

  .brand-logo {
    display: block;
    position: absolute;
    left: 15px;
  }

  .my-name {
    font-size: 25px;
  }
  .hero-content {
    width: 60%;
  }
  
  .line-style {
    margin-top: -16px;
  }

  .skill-section {
    height: 90vh;
  }

  
}

.contact-us-btn:hover {
  background-color: orange;
  color: black;
  border: 3px solid black;
}

.contact-us-btn {
  background-color: black;
  color: #ffc342;
  padding: 13px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #ffc342;
  
}

.line-style {
  width: 5%;
  margin-top: -15px;
}

.skills-title {
  letter-spacing: 2px;
  text-align: center;
}

.project-title {
  color: #ffc342;
  text-align: center;
  padding-top: 50px;
}


@media (max-width: 500px) {
  .skill-section {
    height: 120vh;

  }

  

  
  .column {
    padding-right: 0px;
  }

  .placeholder_img {
    width: 100%;
    height: auto;
  }




}


.chevron-container {
  background-color: white;
  border: none;
}

.chevron-up {
  color: orange;
  font-size: 50px;
  background-color: black;
  padding: 5px;
  margin-top: 25px;
  border-radius: 50%;
  border: 2px solid orange;
}

.chevron-up:hover {
  background-color: orange;
  color: black;
  border: 3px solid black;
  cursor: pointer;
}



.devicon-express-original-wordmark:before {
  color: white;
}

.devicon-django-line-wordmark:before {
  color:green;
}

.devicon-github-original-wordmark:before {
  color: #dbd7d2;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-container button {
  margin: 2px;
}


.link-styling {
  color: white;
  text-decoration: none;
}


.interest-section {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.interest-container {
  margin-top: -45px;
  margin-bottom: 80px;
}

.interest-container > h4 {
  font-size: 17px;
  text-transform: uppercase;
}

.interests {
  margin-right: 10px;
} 
